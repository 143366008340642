import React, { useMemo, useState, useRef, useEffect } from "react"

const OptionsProduct = ({
  variations,
  onChange
}) => {
  const [selectOptions, setSelectOptions] = useState({})
  const variationClone = useMemo(() => {
    // make simple data structure of variation
    return variations.map(item => item.attributes.nodes.reduce((acc, i) => {
      acc[i.label] = i.value

      return acc
    }, {}))
  }, [variations])

  // check value of option exist in variation
  const existVariation = (key, value) => {
    // I don't care when selectOptions without data
    if (Object.keys(selectOptions).length === 0) {
      return true
    }

    return Object.keys(selectOptions).every(selectOptionsKey => {
      if (key === selectOptionsKey) {
        return true
      }
      return variationClone.filter(item => {
        if (selectOptions[selectOptionsKey] === item[selectOptionsKey]) {
          return item[key] === value
        }
        return false
      }).length > 0
    })
  }
  const options = useMemo(() => {
    let valueSelectOptions = Object.values(selectOptions)

    return variations.reduce((acc, variation) => {
      variation.attributes.nodes.forEach(item => {
        if (!(item.label in acc)) {
          acc[item.label] = {}
        }

        if (!(item.value in acc[item.label])) {
          acc[item.label][item.value] = item.value
        }
      })
      return acc
    }, {})
  }, [])

  const filterOption = useMemo(() => {
    // depth clone
    let optionsClone = JSON.parse(JSON.stringify(options))

    Object.keys(optionsClone).forEach(key => {
      let value = optionsClone[key]
      // loop one by one item and check item show or not
      Object.keys(value).forEach(item => {
        if (!existVariation(key, item)) {
          delete optionsClone[key][item]
        }
      })
    })

    return optionsClone
  }, [selectOptions])

  const handleChangeOption = ({key, value}) => {
    let option = {
      ...selectOptions,
      [key]: value
    }

    if (!value) {
      delete option[key]
    }
    setSelectOptions(option)
    if (onChange) {
      onChange(option)
    }
  }

  return (
    <table
      className="variations"
      cellspacing="0">
      <tbody>
        {/* loop variable */}
        {Object.keys(filterOption).map(key => (
          <tr key={key}>
            <td className="label">
              <label for={key}>{key}</label>
            </td>
            <td className="value">
              <span>
                <select
                  name={key}
                  id={key}
                  value={selectOptions[key]}
                  onChange={({target: {value}}) => handleChangeOption({
                    value,
                    key: key
                  })}>
                  <option value="">Choose an option</option>
                  {Object.keys(filterOption[key]).map(ele => (
                    <option value={ele}>{ele}</option>
                  ))}
                </select>
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default OptionsProduct