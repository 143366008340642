import { useMutation } from "@apollo/client"
import { ADD_CART } from "../graphql/mutations/cart/addCart"
import { ADD_CART_ITEMS } from "../graphql/mutations/cart/addCartItems"
import { randomString } from "../helpers/randomString"
import { decodeHTMLEntities } from "../utils/function"

export const useAddToCartByQty = () => {
  const [addToCart] = useMutation(ADD_CART_ITEMS, {
    refetchQueries: [
      "getSimpleCart"
    ],
    awaitRefetchQueries: true
  })
  return {
    addToCart({
      productDatabaseId,
      onCompleted,
      qty = 1,
      variationId,
      onError
    }) {
      addToCart({
        variables: {
          input: {
            clientMutationId: randomString(),
            items: [
              {
                productId: productDatabaseId,
                quantity: qty,
                variationId
              }
            ]
          }
        },
        onCompleted: ({ addToCart }) => {
          if (onCompleted) {
            onCompleted(addToCart)
          }
        },
        onError: (e) => {
          // eslint-disable-next-line no-console
          if (onError) {
            onError()
          }
          alert(decodeHTMLEntities(e.message))
          console.error(e.message)
        }
      })
    }
  }
}