import React, { useEffect, useState } from "react"
import "./product-detail.scss"
import ProductGallery from "../ProductGallery"
import ProductPrice from "../ProductPrice"
import icon1 from "../images/product-icon-1.png"
import icon2 from "../images/product-icon-2.png"
import icon3 from "../images/product-icon-3.png"
import icon4 from "../images/product-icon-4.png"
import icon5 from "../images/product-icon-5.png"
import icon6 from "../images/product-icon-6.png"
import icon7 from "../images/product-icon-7.png"
import icon8 from "../images/product-icon-8.png"
import { useAddToCartByQty } from "../../hooks/useAddToCartByQty"
import { useCart } from "../../hooks/useCart"
import { Link } from "gatsby"
import OptionsProduct from "./OptionsProduct.component"
import NotifyForm from "../NotifyForm/NotifyForm.component"
import { useCartContext } from "../../views/cart/CartContext"
const icons = [
  {
    src: icon1,
    alt: "glutenFree"
  },
  {
    src: icon2,
    alt: "pork"
  },
  {
    src: icon3,
    alt: "woodsmoked"
  },
  {
    src: icon4,
    alt: "beef"
  },
  {
    src: icon5,
    alt: "airDried"
  },
  {
    src: icon6,
    alt: "mediumSpicy"
  },
  {
    src: icon7,
    alt: "hotAndSpicy"
  },
]
const ProductDetail = ({ data,stockStatusLoading }) => {
  const [openNutri, setOpenNutri] = useState(false)
  const [selectOptions, setSelectOptions] = useState({})
  const [adding, setAdding] = useState(false)
  const [qty, setQty] = useState(1)
  const { addToCart } = useAddToCartByQty()
  const [isProductInCart, setIsProductInCart] = useState(false)
  const [filteredIcons, setFilteredIcons] = useState(null);
  const optionsProduct = data?.attributes?.nodes || []
  const variations = data?.variations?.nodes || []
  const { isAdded } = useCart()
  const {refetch,setIsAddToCart} = useCartContext()
  const meta = {}
  data.metaData.forEach(el => {
    meta[el.key] = el.value
  })
  const allImages = [data.image, data.galleryImages.nodes].flat()
  const productType = data.productTypes.nodes[0].name

  const handleOpenNutriBody = () => {
    setOpenNutri(!openNutri)
  }
  const handleQty = (e) => {
      let newQty = parseInt(e.target.value, 10)
      if (newQty <= 0) {
        setQty(1)
      } else {
        setQty(newQty)
      }
  }
  const handleAddToCart = () => {
    let selectVariant
    setIsProductInCart(false)
    if (productType !== "simple") {
      let valueOptionSelect = Object.values(selectOptions)
      variations.map(item => {
        if (!selectVariant) {
          if (
            item.attributes.nodes.every(attr => {
              return valueOptionSelect.includes(attr.value)
            })
          ) {
            selectVariant = item
          }
        }
      })
    }

    if (productType === "simple") {
      setAdding(true)
      addToCart({
        productDatabaseId: data.databaseId,
        qty: qty,
        onCompleted: () => {
          setAdding(false)
          setIsProductInCart(true)
          setIsAddToCart(true)
          refetch()
        },
        onError: () => {
          setAdding(false)
        }
      })
    } else if (selectVariant) {
      setAdding(true)
      addToCart({
        productDatabaseId: data.databaseId,
        variationId: selectVariant.databaseId,
        qty: qty,
        onCompleted: () => {
          setAdding(false)
          setIsProductInCart(true)
          setIsAddToCart(true)
          refetch()
        },
        onError: () => {
          setAdding(false)
        }
      })
    }
  }
  useEffect(() => {
    if (data) {
      const filteredIcons = icons.filter(icon => data.acfProductIcons[icon.alt] !== null);
      setFilteredIcons(filteredIcons)
    }
  }, [data.acfProductIcons]);


  return (
    <>
      {isProductInCart && (
        <div className="woocommerce-message" role="alert">
          <Link href="/cart/" tabindex="1" className="button wc-forward">
            View cart
          </Link>{" "}
          "{data?.name}" has been added to your cart.
        </div>
      )}

      <div className="product-detail-section">
        <div className="wrapper">
          <div className="product-detail__gallery">
            <ProductGallery galleryID="product-gallery" images={allImages} isSales={data.onSale} />
          </div>
          <div className="product-detail__info">
            <h1 className="product-detail--title">{data.name}</h1>
            <ProductPrice
              regularPrice={data.regularPrice}
              salePrice={data.salePrice}
              isSales={data.onSale}
            />
            <div className="product-detail__icons">
              {filteredIcons?.map(ele => (
                <div className="product-detail--icon">
                  <img src={ele.src} alt={ele.alt} />
                </div>
              ))}
              {data?.acfProductIcons?.madeInAustralia && 
               <div className="product-detail--icon--madeInAustralia">
               <img src={icon8} alt="madeInAustralia" />
             </div>
              }
            </div>
            <div
              className="product-detail__body"
              dangerouslySetInnerHTML={{
                __html: data.description
              }}
            ></div>
            <div className="product-detail__nutritional-content">
              <table cellspacing="10">
                <thead>
                  <tr className="toggle-table">
                    <th colspan="3" onClick={handleOpenNutriBody}>
                      Nutritional Content
                    </th>
                  </tr>
                </thead>
                <tbody className={openNutri ? "open" : ""}>
                  <tr className="tb-header">
                    <th>Serving Size 50</th>
                    <th>Avg. Qty per serving</th>
                    <th>Avg. Qty per 100g</th>
                  </tr>

                  <tr>
                    <td>Energy</td>
                    <td>{meta.energy_per_serving}</td>
                    <td>{meta.energy_per_100}</td>
                  </tr>

                  <tr>
                    <td>Protein</td>
                    <td>{meta.protein_per_serving}</td>
                    <td>{meta.protein_per_100}</td>
                  </tr>

                  <tr>
                    <td>Fat, Total*</td>
                    <td>{meta.fat_total_per_serving}</td>
                    <td>{meta.fat_total_per_100}</td>
                  </tr>

                  <tr>
                    <td>Fat, Saturated</td>
                    <td>{meta.fat_saturated_per_serving}</td>
                    <td>{meta.fat_saturated_per_100}</td>
                  </tr>

                  <tr>
                    <td>Carbohydrate</td>
                    <td>{meta.carbohydrate_per_serving}</td>
                    <td>{meta.carbohydrate_per_100}</td>
                  </tr>

                  <tr>
                    <td>- Sugars</td>
                    <td>{meta.sugars_per_serving}</td>
                    <td>{meta.sugars_per_100}</td>
                  </tr>

                  <tr>
                    <td>Sodium</td>
                    <td>{meta.sodium_per_serving}</td>
                    <td>{meta.sodium_per_100}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {!stockStatusLoading && (
              <>
                {data.newStockStatus === "OUT_OF_STOCK" ? (
                  <div className="wrap-notiform-Productdetail">
                    <NotifyForm productName={data.name} productSlug={data.slug} backInStock={data.acfProductData.backInStockNotificationForm}/>
                  </div>
                ) : (
                  <form onSubmit={e => e.preventDefault()} className="product-detail__form-atc">
                    {productType !== "simple" ? (
                      <>
                        <OptionsProduct variations={variations} onChange={setSelectOptions} />
                        <div className="wrapper-qty">
                          <input
                            name="qty"
                            type="number"
                            onChange={handleQty}
                            value={qty}
                          />
                          {optionsProduct.length === Object.keys(selectOptions).length ? (
                            <button type="submit" onClick={() => handleAddToCart()} id="btn-atc">
                              {adding ? "Adding..." : "Add to cart"}
                            </button>
                          ) : (
                            <button type="submit" id="btn-atc" disabled="disabled">
                              {adding ? "Adding..." : "Add to cart"}
                            </button>
                          )}
                        </div>
                      </>
                    ) : (
                      <div className="wrapper-qty">
                        <input name="qty" type="number" min="1" value={qty} onChange={handleQty}/>
                        <button type="submit" onClick={() => handleAddToCart()} id="btn-atc">
                          {adding ? "Adding..." : "Add to cart"}
                        </button>
                      </div>
                    )}
                  </form>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductDetail
