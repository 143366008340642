import { useQuery } from "@apollo/client"
import { GET_SIMPLE_CART } from "../graphql/queries/cart/getSimpleCart"

export const useCart = () => {
  const { loading, error, data, refetch } = useQuery(GET_SIMPLE_CART)
  const count = data?.cart?.contents?.nodes.reduce((acc, item) => acc + item.quantity, 0) || 0
  return {
    loading,
    error,
    count,
    isEmpty: data?.cart?.isEmpty,
    data: data?.cart?.contents?.nodes || [],
    refetch,
    isAdded: (productId, variationId = null) => {
      let result = false
      if (count > 0) {
        data?.cart?.contents?.nodes.forEach((item, index) => {
          if (item.product.node.databaseId === productId) {
            result = true

            if (variationId && item.variation && item.variation.node.databaseId !== variationId) {
              result = false
            }
          }
        })
      }

      return result
    }
  }
}