import React, { useEffect, useState } from "react"
import PhotoSwipeLightbox from "photoswipe/lightbox"
import PhotoSwipe from "photoswipe"
import "photoswipe/style.css"
import Slider from "react-slick"
import "./product-gallery.scss"
import noImage from "../../assets/images/no-img.jpg"

const settings = {
  dots: true,
  infinite: false,
  speed: 100,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false
}

const ProductGallery = (props) => {
  const isSales = props.isSales
  const [featureImg, setFeatureImg] = useState(0)
  useEffect(() => {
    const fullscreenAPI = getFullscreenAPI()
    const fullscreenBtn = "<span>Toggle full</span>"
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#" + props.galleryID,
      children: "a",
      pswpModule: () => PhotoSwipe,
      mouseMovePan: true,
      initialZoomLevel: "fit",
      secondaryZoomLevel: 1.3,
      maxZoomLevel: 1,
      bgOpacity: 1
    })

    lightbox.on("uiRegister", function () {
      lightbox.pswp.ui.registerElement({
        name: "full-screen",
        ariaLabel: "Toggle full screen",
        order: 10,
        isButton: true,
        html: fullscreenBtn,
        onClick: (event, el) => {
          toggleFullscreen()
        }
      })
    })

    lightbox.init()

    // Simple fullscreen API helper, stolen from v5 documentation (Native fullscreen on open)
    function getFullscreenAPI() {
      let api
      let enterFS
      let exitFS
      let elementFS
      let changeEvent
      let errorEvent
      if (document.documentElement.requestFullscreen) {
        enterFS = "requestFullscreen"
        exitFS = "exitFullscreen"
        elementFS = "fullscreenElement"
        changeEvent = "fullscreenchange"
        errorEvent = "fullscreenerror"
      } else if (document.documentElement.webkitRequestFullscreen) {
        enterFS = "webkitRequestFullscreen"
        exitFS = "webkitExitFullscreen"
        elementFS = "webkitFullscreenElement"
        changeEvent = "webkitfullscreenchange"
        errorEvent = "webkitfullscreenerror"
      }
      if (enterFS) {
        api = {
          request: function (el) {
            if (enterFS === "webkitRequestFullscreen") {
              el[enterFS](Element.ALLOW_KEYBOARD_INPUT)
            } else {
              el[enterFS]()
            }
          },
          exit: function () {
            return document[exitFS]()
          },
          isFullscreen: function () {
            return document[elementFS]
          },
          change: changeEvent,
          error: errorEvent
        }
      }
      return api
    }

    // Toggle full-screen mode function
    function toggleFullscreen() {
      if (fullscreenAPI) {
        if (fullscreenAPI.isFullscreen()) {
          fullscreenAPI.exit()
          setTimeout(function () {
            let ele = document.querySelector(".pswp__button--full-screen")
            ele.classList.remove("exit")
          }, 300)
        } else {
          fullscreenAPI.request(document.querySelector(".pswp"))
          setTimeout(function () {
            let ele = document.querySelector(".pswp__button--full-screen")
            ele.classList.add("exit")
          }, 300)
        }
      }
    }

    lightbox.on("uiRegister", function () {
      lightbox.pswp.ui.registerElement({
        name: "custom-caption",
        order: 9,
        isButton: false,
        appendTo: "root",
        html: "<div class=\"text\"></div>",
        onInit: (el, pswp) => {
          lightbox.pswp.on("change", () => {
            const currSlideElement = lightbox.pswp.currSlide.data.element
            let captionHTML = ""
            if (currSlideElement) {
              const hiddenCaption = currSlideElement.querySelector(".hidden-caption-content")
              if (hiddenCaption) {
                // get caption from element with class hidden-caption-content
                captionHTML = hiddenCaption.innerHTML
              } else {
                // get caption from alt attribute
                captionHTML = currSlideElement.getAttribute("alt")
              }
            }
            el.children[0].innerHTML = captionHTML || ""
          })
        }
      })
    })

    return () => {
      lightbox.destroy()
      lightbox = null
    }
  }, [])

  useEffect(() => {
    const targets = document.querySelectorAll(".slick-dots button")
    if (targets.length > 1) {
      targets[featureImg].click()
    }
  }, [featureImg])

  const handleTriggerDot = e => {
    let allDots = document.querySelectorAll(".control-slider .gallery-wrapper__item-dot img")
    allDots.forEach(item => {
      item.classList.remove("active")
    })
    let ele = e.target
    ele.classList.add("active")
    setFeatureImg(parseInt(ele.getAttribute("data-index"), 10))
  }

  const handleZoomIn = e => {
    let target = e.target
    let rect = target.getBoundingClientRect()
    let win = target.ownerDocument.defaultView
    let top = rect.top + win.pageYOffset
    let left = rect.left + win.pageXOffset

    target.style.transformOrigin =
      ((e.pageX - left) / target.offsetWidth / 1.5) * 100 +
      "% " +
      ((e.pageY - top) / target.offsetHeight / 1.5) * 100 +
      "%"
  }
  return (
    <div className="pswp-gallery" id={props.galleryID}>
      <div className="gallery-wrapper">
        <div className="gallery-wrapper__main">
          {props.images.map((image, index) => (
            <a
              id="zoom-img"
              className={`zoom-img ${index === featureImg ? "show" : ""}`}
              href={image ? image.mediaItemUrl : noImage}
              alt={image ? image.altText : ""}
              data-pswp-height="860px"
              data-pswp-width="860px"
              target="_blank"
              rel="noreferrer"
            ></a>
          ))}
          <div className="gallery-wrapper__list">
            {isSales && <span className="on-sale">Sale!</span>}
            <Slider {...settings}>
              {props.images.map((image, index) => (
                <div key={props.galleryID + "-" + index} className="gallery-wrapper__item">
                  <img width="600"
                    src={image ? image.mediaItemUrl : noImage}
                    alt={image ? image.altText : ""}
                    onMouseMove={handleZoomIn} />
                </div>
              ))}
            </Slider>
          </div>
          {props.images.length > 1 && (
            <div className="control-slider">
              {props.images.map((image, index) => (
                <div key={props.galleryID + "-" + index} className="gallery-wrapper__item-dot">
                  <img
                    className={featureImg === index ? "active" : ""}
                    src={image.mediaItemUrl}
                    alt={image.altText}
                    onClick={handleTriggerDot}
                    data-index={index}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductGallery
