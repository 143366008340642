import React, { useMemo } from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import ProductDetail from "../../components/ProductDetail"
import Heading from "../../components/Heading"
import "./product.scss"
import ProductCart from "../../components/ProductCart"
import { getStockStatus } from "../../utils/function"
import Loading from "../../components/Loading"
import Seo from "../../components/seo"
import { useGetAllProducts } from "../../hooks/useGetAllProducts"

export const query = graphql`
query($slug: String) {
  wpProduct(slug: { eq: $slug }) {
     
      ... on WpVariableProduct {
        id
        name
        regularPrice
        salePrice
        databaseId
        onSale
        description
        acfProductIcons{
          glutenFree
          pork
          beef
          airDried
         woodsmoked
         mediumSpicy
         hotAndSpicy
         madeInAustralia
       }
        attributes {
          nodes {
            attributeId
            id
            label
            name
            options
            position
            variation
          }
        }
        variations {
          nodes {
            databaseId
            attributes {
              nodes {
                attributeId
                id
                label
                name
                value
              }
            }
            id
            price
          }
        }
        productTypes {
          nodes {
            name
          }
        }
        related {
          nodes {
            ... on WpSimpleProduct {
              id
              name
              databaseId
              regularPrice
              salePrice
              stockStatus
              onSale
              slug
              acfProductIcons{
          glutenFree
          pork
          beef
          airDried
         woodsmoked
         mediumSpicy
         hotAndSpicy
         madeInAustralia
       }
              image {
                publicUrl
                altText
              }
            }
          }
        }
        acfProductData{
          backInStockNotificationForm
        }
      }
      ... on WpSimpleProduct {
          id
          name
          regularPrice
          salePrice
          onSale
          databaseId
          stockStatus
          description
          slug 
        acfProductIcons{
          glutenFree
          pork
          beef
          airDried
         woodsmoked
         mediumSpicy
         hotAndSpicy
         madeInAustralia
       }
          productTypes {
            nodes {
              name
            }
          }
          related {
            nodes {
              ... on WpSimpleProduct {
                id
                name
                regularPrice
                databaseId
                salePrice
                stockStatus
                onSale
                slug
                acfProductIcons{
          glutenFree
          pork
          beef
          airDried
         woodsmoked
         mediumSpicy
         hotAndSpicy
         madeInAustralia
       }
                image {
                  publicUrl
                  altText
                }
              }
            }
          }
          acfProductData{
            backInStockNotificationForm
          }
      }
        image {
          mediaItemUrl
        }
        galleryImages{
          nodes {
            mediaItemUrl
            altText
          }
        }
        metaData {
          value
          key
        }
    }
  }
 `

const ProductPage = ({ data }) => {
  const productDetail = data.wpProduct
  const seo = data.wpProduct.acfSeoData
  const relatedProducts = useMemo(() => productDetail.related.nodes.filter((item) => item.stockStatus === "IN_STOCK"), [productDetail.related.nodes])
  const { listProducts, loading } = useGetAllProducts()
  return (
    <Layout>
      <Seo title={seo?.title ? seo?.title : `${productDetail?.name}`} description={seo?.metaDescription} />
      <ProductDetail data={{ ...productDetail, newStockStatus: getStockStatus(productDetail.id, listProducts?.edges) }} stockStatusLoading={loading} />
      <div className="related-product-section">
        <Heading
          title="YOU MAY ALSO LIKE"
          color="#000"
        />
        {
          !loading ?
            <div className='related-products'>
              {relatedProducts.splice(0, 3).map((relatedProduct) => {
                return (<ProductCart
                  productDatabaseId={relatedProduct.databaseId}
                  key={relatedProduct.id}
                  isSales={relatedProduct.onSale}
                  regularPrice={relatedProduct.regularPrice}
                  salePrice={relatedProduct.salePrice}
                  productName={relatedProduct.name}
                  productImage=
                  {(relatedProduct.image) ? relatedProduct.image.publicUrl : ""}
                  slug={relatedProduct.slug}
                  isProductConfig={relatedProduct.__typename === "VariableProduct"}
                  stockStatus={getStockStatus(relatedProduct.id, listProducts?.edges)}
                >
                </ProductCart>)
              }
              )}
            </div> : <div className="products-loading"><Loading /></div>
        }

      </div>
    </Layout>
  )
}

export default ProductPage